@font-face {
    font-family: "SourceSansPro-Regular";
    src: url("../../fonts/source-sans/SourceSansPro-Regular.ttf");
}
@font-face {
    font-family: "SourceSansPro-Bold";
    src: url("../../fonts/source-sans/SourceSansPro-Bold.ttf");
}
@font-face {
    font-family: "SourceSansPro-SemiBold";
    src: url("../../fonts/source-sans/SourceSansPro-SemiBold.ttf");
}