﻿/*
 *  Document   : theme-purple.scss
 *  Author     : RedStar Template
 *  Description: This scss file for purple theme style classes
 */
@import '_theme-color-variables';

.theme-purple {
  .navbar {
    background-color: transparent;
  }
  .navbar.active {
    background: #8e2de2; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #4a00e0,
      #8e2de2
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #4a00e0,
      #8e2de2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    .nav {
      > li {
        > a {
          color: #fff;
        }
      }
    }
    .collapse-menu-icon .mat-icon {
      color: #fff;
    }
    .navbar-right .nav-notification-icons .mat-icon {
      color: #fff;
    }
  }

  .nav {
    > li {
      > a {
        &:hover {
          background-color: $theme-purple-nav-anchor_hover;
          text-decoration: none;
        }

        &:focus {
          background-color: $theme-purple-nav-anchor_focus;
          text-decoration: none;
        }
      }
    }
  }

  .bars {
    color: $theme-purple-bar;
  }

  .sidebar {
    .menu {
      .list {
        li {
          &.active {
            background-color: $theme-purple-menu-list-active;
          }
          a {
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            i,
            span {
              -moz-transition: all 0.3s;
              -o-transition: all 0.3s;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
            }
          }
        }

        .ml-menu {
          background-color: $theme-purple-menu-list-submenu;
        }
      }
    }

    .legal {
      background-color: $theme-purple-legal-bg;

      .copyright {
        a {
          color: $theme-purple !important;
        }
      }
    }
  }

  .page-item.active .page-link {
    background-color: $theme-purple;
    border-color: $theme-purple;
    color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  }

  .btn-primary {
    background-color: $theme-purple !important;
    color: #fff !important;
    border-color: $theme-purple !important;
    &:hover {
      background-color: $theme-purple !important;
      color: #fff !important;
    }
    &:active {
      background-color: $theme-purple !important;
      color: #fff !important;
    }
    &:focus {
      background-color: $theme-purple !important;
      color: #fff !important;
    }
    &:disabled {
      background-color: $theme-purple !important;
      color: #fff !important;
    }
  }
  .btn-outline-primary {
    background: 0 0 !important;
    color: $theme-purple !important;
    border: 1px solid $theme-purple !important;
    &:hover {
      background: $theme-purple !important;
      color: #fff !important;
      border: 1px solid $theme-purple !important;
    }
  }
  .timelineLabelColor strong {
    color: $theme-purple !important;
  }
  .top-sidebar {
    .horizontal-menu {
      li {
        &.active {
          > a {
            color: $theme-purple;
          }
        }
        a {
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          &:hover {
            color: $theme-purple;
          }
        }
      }
    }
  }

  .nav-tabs > li > a:before {
    border-bottom: 2px solid $theme-purple;
  }
}
/*Logo Header Background Color*/
.logo-purple {
  .navbar-header {
    background-color: $theme-purple;
  }
}
